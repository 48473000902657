@import '../../../../assets/css/base.scss';

.license-block {
  .list-block-item.card {
    background-color: transparent !important;
    border-radius: 0;
    margin-top: 0;
    padding: 0;

    .card-body {
      .MuiAccordion-root {
        background-color: var(--grayscale_96);
        border-radius: 12px;
        box-shadow: none;
        margin-bottom: 8px;
        padding: 0 20px;

        &.product-accordion {
          .product-summary .product-name {
            font-weight: 500;
          }

          .product-summary .num-of-licenses {
            color: var(--success_48);
            font-size: toRem(12px);
          }

          .product-details .product-list {
            list-style: none;
            padding: 0;
            margin: 0;

            .product-list-item-wrapper {
              padding: 10px 0;

              .product-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .product-list-item-name {
                  font-size: toRem(16px);
                  color: var(--greyscale_08);
                }

                .product-list-item-sku {
                  font-size: toRem(10px);
                }
              }
            }
          }
        }

        &::before {
          background-color: transparent;
        }

        .MuiAccordionSummary-expandIconWrapper {
          margin-right: 2px;
          .MuiSvgIcon-root {
            height: 1.5em;
            width: 1.5em;
          }
        }

        .MuiAccordionSummary-root,
        .MuiAccordionDetails-root {
          padding: 0;
        }
      }
    }
  }
}

.confirm-btn {
  &.disabled {
    color: var(--grayscale_32);
    background-color: var(--greyscale_88);
    outline: 0;
    border: 0;
  }
}
.warning-container {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    margin: 12px 0;
    align-items: center;
    border-radius: 12px;
    background: var(--warning_96);

    .warning-message {
      display: flex;
      color: var(--warning_24);
      font-size: toRem(14px);
      font-weight: 400;
      line-height: 20px;

      svg {
        margin-right: 8px;
        width: 30px;
      }
    }
  }
