@import '../../assets/css/base';

.all-events-container {
  color: var(--primary_40);
}

.seprator-tags {
  width: 100%;
  height: 2px;
  background-color: var(--greyscale_88);
}

.search-dashboard-allevents {
  display: flex;
  gap: 1rem;
  width: 100%;
  margin-top: -35px;
  margin-bottom: 5px;
  align-items: center;

  .serach-box {
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    padding: 18px 20px;
    width: 100%;
    height: 50px;
    background: var(--brand_white);
    border: 1.5px solid var(--greyscale_88);
    border-radius: 12px;
    align-items: center;

    &.search-modal {
      width: 100%;
      margin-top: -30px;
    }

    .textbox {
      width: 100%;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      color: var(--greyscale_40);
      margin-left: 10px;
      text-align: left;

      &.serach-text {
        color: var(--greyscale_08);
      }
    }
  }

  .voice-conatiner-all-result {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .outer-view {
      height: 65px;
      width: 65px;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      background: transparent;
      align-items: center;

      &.voice-listening {
        background: var(--primary_96);
        animation: mymove 1s infinite;
      }

      @keyframes mymove {
        from {
          background-color: var(--primary_88);
        }

        to {
          background-color: var(--primary_96);
        }
      }

      .mic-btn {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;
        height: 50px;
        width: 50px;
        background: var(--primary_40);
        border-radius: 12px;
      }
    }
  }
}

.search-filter-category {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 10px;

  .left-conatainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;

    .all-result-text {
      font-style: normal;
      font-weight: 700;
      font-size: toRem(24px);
      line-height: 32px;
      display: flex;
      align-items: center;
      color: var(--all_result);
    }
    .event-count {
      margin-left: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      color: var(--greyscale_40);
    }
  }

  .right-filter-box {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;

    .time-box {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 5px;
      gap: 8px;
      border-radius: 12px;

      .form-select {
        background-color: var(--brand_white);
        border: 1px solid var(--greyscale_88);
        border-radius: 12px;
        //padding-left: 40px;
        color: var(--primary_08);
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;

        .options {
          background-color: brown !important;
          padding: 15px !important;
        }
        &:hover {
          border: 2px solid var(--primary_48);
          transition: none;
          box-shadow: none;
        }
        &:focus {
          border: 2px solid var(--primary_48);
          transition: none;
          box-shadow: none;
        }
      }

      .left-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        .label-time {
          margin-left: 5px;
          font-style: normal;
          font-weight: 500;
          font-size: toRem(14px);
          line-height: 20px;
          color: var(--greyscale_40);
        }
      }
    }
  }
}

.all-events-container {
  width: 100%;
  height: 100%;

  .no-data-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 35%;

    .no-result {
      margin-top: 10px;
      font-style: normal;
      font-weight: 600;
      font-size: toRem(18px);
      line-height: 24px;
      text-align: center;
      color: var(--text_color);
    }

    .message-no-result {
      margin-top: 10px;
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
      text-align: center;
      color: var(--greyscale_40);
    }

    .all-event-back-conatiner {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .click-here {
        font-style: normal;
        font-weight: bold;
        font-size: toRem(14px);
        line-height: 20px;
        text-align: center;
        color: var(--primary_40);
        text-decoration: underline;
        cursor: pointer;
      }

      .to-see-all-events {
        margin-left: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        text-align: center;
        color: var(--greyscale_40);
      }
    }
  }

  .fixed-icons-wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    display: flex;

    .filter-image,
    .filterDate-image {
      cursor: pointer;
    }

    .filterDate-image {
      margin-right: 8px;
    }
  }

  .events-all-category-wrapper {
    border-radius: 12px;
    text-align: left;
    margin-bottom: 16px;

    .event-wrapper-list {
      display: flex;
      padding: 12px;
      flex-direction: column;

      .event-list-image {
        max-width: 100%;
        max-height: 235px;
        box-shadow: 0px 25px 50px -12px rgba(var(--greyscale-custom1), 0.15);
        border-radius: 8px;
        overflow: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        position: relative;
        z-index: -1;

        img {
          max-width: 100%;
          width: auto;
        }

        .event-image-time {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          padding: 4px 8px;
          position: absolute;
          right: 12px;
          top: 12px;

          background: rgba(var(--greyscale-custom1), 0.4);
          backdrop-filter: blur(5px);
          /* Note: backdrop-filter has minimal browser support */

          border-radius: 8px;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: white;

          svg {
            margin-right: 5px;
          }
        }
      }

      .event-list-content {
        padding-left: 12px;
        min-height: 60px;
        display: flex;
        align-items: center;
        border-width: 0px 1.5px 1.5px 1.5px;
        border-style: solid;
        border-color: var(--greyscale_88);
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .event-timestamp {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: var(--greyscale_40);
          margin-top: 2px;
        }

        .event-content-list-wrapper {
          margin-left: 12px;
        }

        .event-name {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-transform: capitalize;
          margin-top: 2px;
          color: var(--grayscale_16);
        }

        .event-image {
          padding: 0;
          display: flex;
          width: 36px;
          height: 36px;
          background: var(--primary_96);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--primary_32);
        }
      }
    }
  }

  .sticky-header {
    background-color: white;

    .header {
      text-align: left;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      padding-bottom: 16px;
      color: var(--greyscale_40);
    }
  }

  .scroll-container {
    height: calc(100vh - 250px);
    overflow: auto;

    &::-webkit-scrollbar {
      display: none !important;
    }
  }
}
