@import '../../../../assets/css/_utilities.scss';

.focus-activity-zone {
  display: flex;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  justify-content: flex-start;
  height: 136px;
  margin-top: 20px;
  flex-direction: column;

  .activity-field-label {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(16px);
    line-height: 20px;
    display: flex;
    color: var(--greyscale_08);
  }

  .focus-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    align-items: center;

    .left-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .label-left {
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: 16px;
        display: flex;
        color: var(--greyscale_40);
      }

      .btn-container {
        margin-top: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        background: var(--primary_40);
        border-radius: 10px;
        cursor: pointer;
      }
    }

    .middle-container {
      width: 100%;
      pointer-events: none;
      cursor: none;
      padding-left: 12px;
      padding-right: 12px;
      align-items: center;
      margin-top: 15px;

      .bar-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        isolation: isolate;
        background: var(--greyscale_88);
        border-radius: 12px;
        width: 100%;
        height: 8px;
      }
    }

    .right-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .label-right {
        font-style: normal;
        font-weight: 500;
        font-size: toRem(12px);
        line-height: 16px;
        display: flex;
        color: var(--greyscale_40);
      }

      .btn-container {
        margin-top: 3px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 12px;
        background: var(--primary_40);
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
