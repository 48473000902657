@import '../../../../assets/css/_utilities.scss';

.sub-header-analytics {
  font-style: normal;
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  margin-top: 18px;
  margin-bottom: 18px;
}

.range-field-label {
  color: var(--brand_black);
  font-weight: 900;
  font-size: toRem(16px);
  line-height: 16px;
}

.sitemodal-inner {
  margin-top: 10px;
}

.settings-block-analytics {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;
  background: var(--grayscale_96);
  border-radius: 12px;

  .add-edit-icon {
    margin-right: 10px;
    cursor: pointer;
    color: var(--greyscale_08);
    &.off-status {
      pointer-events: none;
      cursor: not-allowed;
      color: var(--greyscale_40);
    }
  }
}

.advanced-activity-zone {
  display: flex;
  padding: 20px;
  gap: 12px;
  background: var(--grayscale_96);
  border-radius: 12px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .activity-field-label {
    font-style: normal;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
  }
}
