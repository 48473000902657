@import '../../assets/css/base.scss';

.MuiAccordion-root {
  background-color: var(--grayscale_96);
  border-radius: 12px !important;
  box-shadow: none;
  margin-bottom: 8px;
  padding: 0 12px 0 16px;

  &.base-accordion {
    border-radius: 12px;
    border: 0;
    background: var(--grayscale_96);
    box-shadow: none;
    margin-bottom: 15px;

    &::before {
      border: 0;
      display: none;
    }

    &::first-of-type {
      border-radius: 12px;
    }

    .base-summary .base-title {
      font-weight: 500;
    }

    .base-details {
      &-list {
        list-style: none;
        padding: 0;
        margin: 0 0 15px 0;

        &-item-wrapper {
          padding: 10px 0;
        }

        &-item {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-name {
            font-size: toRem(16px);
            color: var(--greyscale_08);
          }
        }

        &-wrapper {
          padding-bottom: 10px;
        }
      }
    }
  }

  &::before {
    background-color: transparent;
  }

  .MuiAccordionSummary-expandIconWrapper {
    margin-right: 2px;
    .MuiSvgIcon-root {
      height: 1.5em;
      width: 1.5em;
    }
  }

  .MuiAccordionSummary-root,
  .MuiAccordionDetails-root {
    padding: 0;
  }
  .MuiRadio-root {
    padding: 0 2px 0 0;
    background: var(--brand_white);
  }
}
