.events-list-panel {
  padding-left: 38px;
}

.close-button {
  display: inline-flex;
  justify-content: space-between;
  height: 44px;
  width: 92px;
  padding-left: 14px;
  padding-right: 14px;
  background-color: var(--grayscale_24) !important;
  border-color: var(--grayscale_32) !important;
  &:focus {
    background-color: var(--grayscale_24) !important;
    border-color: var(--grayscale_32) !important;
  }
}

.devices-row {
  --bs-gutter-x: 0.5rem;
}
