@import '../../../assets/css/base';

.create-edit-container {
  width: 100%;

  .create-edit-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .label-title {
      color: var(--brand_black);
      font-size: toRem(20px);
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
    }
  }

  .choose-day-conatiner {
    width: 100%;

    .title-day {
      margin-top: 30px;
      color: var(--brand_black);
      font-size: toRem(16px);
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }

    .day-list-container {
      margin-top: 10px;
      border-radius: 12px;
      background: var(--grayscale_96);
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      padding: 12px;

      .day-blocks {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        padding: 12px 20px;
        background: var(--greyscale_88);
        border-radius: 8px;
        color: var(--greyscale_40);
        font-size: toRem(14px);

        &.selected {
          background: var(--primary_40);
          color: var(--brand_white);
        }

        // @media (min-width:1000px) and (max-width:1200px) {
        //     height: 45px;
        //     width: 45px;
        // }

        // @media (min-width:400px) and (max-width:1000px) {
        //     height: 35px;
        //     width: 35px;
        // }

        .label-value {
          text-align: center;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }

  .selected-days {
    margin-top: 10px;
    color: var(--greyscale_40);
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .label-title {
    margin-top: 20px;
    color: var(--brand_black);
    font-size: toRem(16px);
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }

  .timezone {
    color: var(--greyscale_40);
    font-size: toRem(14px);
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .time-slot-container {
    margin-top: 10px;
    border-radius: 12px;
    background: var(--grayscale_96);
    padding: 0px 10px 15px 10px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    align-items: center;

    .time-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      align-items: center;

      .timer-start-end {
        width: 50%;
        display: flex;
        justify-content: center;
        gap: 8px;
        border-radius: 12px;
        font-size: toRem(16px);
        align-items: center;

        .time-label {
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: var(--text_color);
        }

        .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
          padding: 10px 10px;
        }
      }

      .seprator {
        color: var(--greyscale_08);
        margin-left: 10px;
        margin-right: 10px;
      }
    }

    .notification-sheduled-text {
      color: var(--greyscale_40);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
    }
  }
}
