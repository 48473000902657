@import '../../../assets/css/base';

.save-view-modal,
.save-as-new-view-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .modal-dialog .modal-content {
    padding: 16px 32px;

    .save-view-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      padding-bottom: 16px;
    }

    .view-name-input-wrapper {
      display: flex;
    }

    .view-name-input {
      display: flex;
      background-color: var(--brand_white);
      border: 1.5px solid var(--greyscale_88);
      border-radius: 12px;
      padding: 12px 16px;
      align-items: flex-start;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .save-view-button-wrapper {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.update-view-modal {
  .modal-dialog .modal-content {
    width: 542px;

    .modal-header {
      padding-top: 0;
    }

    .modal-body {
      padding-bottom: 0;

      .update-view-button-wrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        .update-btn {
          font-family: 'Noto Sans';
          font-size: toRem(14px);
          font-weight: 600;
          height: 44px;
          margin-right: 8px;
        }
      }
    }
  }
}

.create-clip-modal {
  .modal-header {
    padding-top: 0;
  }

  .modal-body {
    .create-clip {
      &-snapshot-wrapper {
        aspect-ratio: 16/9;
        width: 478px;
        border-radius: 12px;

        .image-wrapper {
          img {
            width: 100%;
            aspect-ratio: 16/9;
            border-radius: 12px;
          }
        }

        .create-clip-video-processing {
          border-radius: 12px;
          height: 100%;
          width: 100%;

          .spinner {
            position: absolute;
            z-index: 20;
            top: 18%;
            left: calc(50% + 12px);
          }

          &-snapshot {
            border-radius: 12px;
            position: absolute;

            img {
              width: 100%;
              aspect-ratio: 16/9;
            }
          }

          &-status {
            position: absolute;
            z-index: 10;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: 268px;
            width: 100%;
            margin-top: 0px;
            margin-left: auto;
            margin-right: auto;
            border-radius: 12px;
            background: rgba(0, 16, 41, 0.6);

            &-title {
              color: var(--brand_white);
              text-align: center;
              font-size: toRem(14px);
              font-weight: 600;
              line-height: toRem(20px);
              margin-bottom: 8px;
              text-align: center;
            }

            &-message {
              color: var(--primary_88);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: normal;
              text-align: center;
              width: 292px;
            }
          }
        }
      }

      &-notification-wrapper {
        .notification {
          display: flex;
          flex-direction: column;
          padding: 12px;
          align-items: flex-start;
          gap: 6px;
          align-self: stretch;
          border-radius: 12px;

          &.success {
            background: var(--success_96);

            .success-title-wrapper {
              .success-title {
                color: var(--success_24);
                font-size: toRem(16px);
                font-weight: 600;
                line-height: toRem(20px);
                margin-left: 8px;
              }
            }
          }

          &.error {
            border-radius: 12px;
            background: var(--error_96);

            .error-title-wrapper {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .error-title {
                color: var(--error_24);
                font-size: toRem(16px);
                font-weight: 600;
                line-height: toRem(18px);
                margin-left: 8px;
              }
            }

            .error-description {
              color: var(--error_24);
              font-size: toRem(14px);
              font-weight: 400;
              line-height: toRem(16px);
              padding-left: 28px;
            }
          }
        }
      }

      &-incidents-wrapper {
        .incident-select {
          .incident-item-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }

      &-shortcuts-wrapper {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 600;
        line-height: toRem(20px);
      }

      &-shortcut-icon {
        display: flex;
        padding: 20px 16px;
        // flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        flex: 1 0 0;
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);
        background: var(--brand_white);
        width: 100%;
        color: var(--greyscale_40);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: toRem(20px);
        text-transform: none;

        &:hover {
          border: 1.5px solid var(--greyscale_88);
          background: #f3f5f7;
        }

        &:disabled {
          border: 1.5px solid var(--greyscale_88);
          opacity: 0.3;
          background: var(--brand_white);
        }

        span {
          margin-right: 0;
        }
      }
    }

    .clip {
      &-duration-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 16px;
        align-self: stretch;
      }

      &-time {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex: 1 0 0;
      }

      &-duration-time {
        color: var(--brand_black);
        font-size: toRem(16px);
        font-weight: 600;
        line-height: toRem(20px);

        &-box {
          display: flex;
          padding: 16px 24px;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          gap: 2px;
          align-self: stretch;
          border-radius: 10px;
          background-color: var(--grayscale_96);

          .clipper-date {
            color: var(--greyscale_08);
            font-size: toRem(16px);
            font-weight: 400;
            line-height: toRem(20px);
          }

          .clipper-time-with-timezone {
            color: var(--greyscale_08);
            font-size: toRem(18px);
            font-weight: 600;
            line-height: toRem(24px);
          }
        }
      }

      &-start-time {
      }

      &-end-time {
      }

      &-comment {
        border-radius: 12px;
        border: 1.5px solid var(--greyscale_88);

        .MuiInputBase-multiline
          .MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputMultiline::placeholder {
          color: var(--greyscale_40);
          opacity: 1;
          font-size: toRem(16px);
          font-weight: 500;
          line-height: toRem(20px);
        }

        &:hover {
          border-color: var(--greyscale_88);
        }
      }

      &-tags {
        &-title {
          color: var(--greyscale_08);
          font-size: toRem(20px);
          font-weight: 600;
          line-height: toRem(28px);
        }

        &-content {
        }
      }
    }

    .char-count {
      font-size: toRem(12px);
      line-height: toRem(20px);
      text-align: right;
    }
  }
}

.device-setup-modal {
  .modal-dialog.modal-md.modal-dialog-centered {
    .modal-content {
      padding: 32px !important;
    }
  }
  .modal-dialog .modal-content {
    width: 542px;
    min-width: 542px;
    padding: 32px !important;
    .modal-body {
      padding-bottom: 0;

      .device-setup-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
        padding: 0px;
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }
      .css-mkl144 {
        padding: 0px;
      }
      .css-mkl144.Mui-checked {
        padding: 0px;
        color: var(--primary_40) !important;
      }
      .MuiFormGroup-root .MuiRadio-root {
        padding: 0px;
        &.Mui-checked {
          color: var(--primary_40);
        }
      }
      .setup-loader{
        color: var(--brand_white);
      }
      & .back-arrow-button{
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_40);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-bottom: 4px;
        width: fit-content;
        cursor: pointer;
      }
      & .back-arrow-button-disabled{
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_88);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        margin-bottom: 4px;
        width: fit-content;
        cursor: auto;
      }
      .step-icon{
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_40);
        margin-right: 8px;
      }
      .step-icon-disabled{
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_88);
        margin-right: 8px;
      }
      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;
        & .search-icon {
            position: absolute;
            z-index: 9;
            margin: 12px 8px 12px 16px;
        }
        & .search-input {
            display: flex;
            padding: 16px 20px 16px 40px;
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
            align-self: stretch;
            border-radius: 10px;
            border: 1.5px solid var(--hGreyscale_56);
            background: var(--brand_white);
            color: var(--greyscale-40);
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px; /* 142.857% */
            width: 100%;
          &:hover {
            background-color: var(--brand_white) !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }
          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }
      }
      .selected-opt{
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--primary_96);
        color: var(--primary_32);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px; /* 133.333% */
        margin-left: 8px;
      }
      .discription{
        color: var(--greyscale_56);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        margin-top: 16px;
      }
      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }
      .stepIcon > img {
        margin-left: 0;
        margin-top: 0;
      }
      .device-setup-modal-title {
        color: var(--greyscale_08);
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
      }
      .device-setup-modal-subTitle {
        color: var(--brand_black);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 125% */
      }
      .location-conatiner{
        display: flex;
        max-height: 273px;
        padding: 12px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--greyscale_96);
        color: var(--greyscale_08);
        overflow: auto;
        .location-item {
          display: flex;
          padding: 6px 0px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px; /* 142.857% */
        }
      }
      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }
      .site-modal-close {
        cursor: pointer;
        color: var(--dark_gray);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }
      .site-modal-close:hover {
        color: var(--button-color-1); /* Hover color */
      }
      .site-modal-close:active {
        color: var(--dark_orange); /* Color on press */
      }
    }
  }
}

.delete-device-modal {
  .modal-header {
    padding-bottom: 0;
    padding-top: 22px;
  }

  .modal-dialog .modal-content {
    padding: 32px !important;

    .delete-device-modal-description {
      color: var(--greyscale_56);
      font-size: toRem(14px);
      padding-bottom: 16px;
    }

    .delete-device-button-wrapper {
      padding-top: 8px;
      padding-bottom: 8px;
    }
    .delete-device-loader {
      background: var(--error_64);
    }
  }
}
