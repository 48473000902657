@import '../../assets//css/base.scss';

.sortable-list-table {
  thead {
    border-spacing: 0;

    th {
      border: 0;
      color: var(--greyscale_56);
      max-width: 120px;

      .header-sort {
        cursor: pointer;
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .column-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100px;
          text-align: left;

          .filter-action {
            height: toRem(24px);
            width: toRem(24px);
            flex-shrink: 0;
          }
        }

        .sort-icon {
          font-size: 1.5rem;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        cursor: pointer;
        background-color: var(--greyscale_88);
      }

      &.row-item-disabled:hover {
        cursor: default;
        background-color: var(--grayscale_96);
      }

      td {
        max-width: 120px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-left: 0;
        border-right: 0;

        .date-field {
          display: inline-flex;
          align-items: center;

          .calendar-icon {
            margin-right: 0.406rem;
          }

          .exclamation-icon {
            margin-right: 0.406rem;
            color: var(--warning_48);
          }

          .warning-date {
            color: var(--warning_48);
          }
        }

        .action {
          &-field {
            margin-left: -10px;
          }
        }
      }
    }
  }
}
