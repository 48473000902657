@import 'utilities';

body {
  background: var(--grayscale_96);
  height: 100vh;
  width: 100%;
}

.fullscreen-enabled {
  .wrapper-app {
    .stream-timeline-wrapper {
      max-height: calc(100vh - 150px);
    }
    .multiviewcontrols {
      max-height: calc(100vh - 150px);
      overflow-y: auto;
    }
  }
}
.wrapper-app {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  cursor: pointer;
  .multiviewcontrols {
    max-height: calc(100vh - 280px);
    overflow-y: auto;
  }
  &.live-streaming {
    flex-direction: column;
  }

  .stream-timeline-wrapper {
    aspect-ratio: 16 / 9;
    width: 100%;
    position: relative;
    background: var(--greyscale_08);
    border-radius: 4px;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 315px);
    &.device-setting {
      overflow: hidden;
      pointer-events: none;
    }
    &.multi-stream-wrapper {
      background: none;
      max-height: calc(100vh - 290px);
    }
    &.image-included {
      aspect-ratio: unset;
    }
    .events-icons-wrapper {
      position: absolute;
      z-index: 1;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      display: flex;
      top: 50%;
      margin-top: -24px;
      .icons-image {
        width: 48px;
        height: 48px;
        background: rgba(var(--greyscale-custom1), 0.4);
        backdrop-filter: blur(5px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.next-icon {
          margin-right: 16px;
        }
        &.previous-icon {
          margin-left: 16px;
        }
      }
    }
    &.timeline-controls-page {
      aspect-ratio: auto;
      // min-height: 130px;
      background: none;
      margin-top: 24px;
      &.search-page-event {
        margin-top: -110px;
        max-width: calc(100% - 16px);
      }
    }
    .video-processing {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .previous-block,
    .next-block {
      width: 49%;
      height: 65%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      top: 15%;
    }
    .previous-block {
      left: 0;
    }
    .next-block {
      right: 0;
    }
    .block-hide-panel {
      width: 100%;
      height: 65%;
      position: absolute;
      z-index: 1;
      cursor: pointer;
      top: 15%;
    }
    .timeline-icons {
      position: absolute;
      top: 16px;
      left: 26px;
      background-color: rgba(var(--greyscale-custom1), 0.4);
      backdrop-filter: blur(5px);
      border-radius: 12px;
      padding: 16px;
      display: flex;
      align-items: center;
      z-index: 2;
      &.hide-controls {
        visibility: hidden;
      }
      button,
      a {
        margin-right: 23px;
        cursor: pointer;
        display: flex;
        background: none;
        border: none;
        padding: 0;
        &:last-child {
          margin-right: 0;
        }
        &:active,
        &:focus,
        &:hover {
          background: none;
          border: none;
          padding: 0;
        }
      }
      .mins,
      .mins:hover,
      .mins:focus {
        background: rgba(var(--greyscale-custom3), 0.2);
        border-radius: 12px;
        padding: 4px 8px;
        color: #fff;
        font-size: toRem(12px);
        line-height: 16px;
      }
      &.live {
        bottom: 16px;
        top: auto;
        left: 50%;
        right: auto;
        margin-left: -40px;
        padding: 0;
        background: rgba(var(--greyscale-custom1), 0.4);
        .live-button {
          color: #fff;
          padding: 11px 18px;
          font-size: toRem(14px);
          line-height: 20px;
          font-weight: 600;
          display: inline-block;
          text-decoration: none;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--error_64);
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
      &.golive {
        right: 88px;
        top: 24px;
        left: auto;
        background: rgba(var(--error80-custom1), 0.2);
        padding: 0;
        display: none;
        &.live_btn_visible {
          display: block;
          right: auto;
          left: 50%;
          margin-left: -50px;
          top: auto;
          bottom: 20px;
        }
        .golive-button {
          color: var(--brand_white);
          padding: 11px 18px;
          font-size: toRem(14px);
          line-height: 20px;
          font-weight: 600;
          text-decoration: none;
          display: flex;
          align-items: center;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background-color: var(--error_64);
            border-radius: 50%;
            margin-right: 8px;
          }
        }
      }
      &.cross {
        right: 16px;
        left: auto;
      }
      &.live-stream {
        a {
          padding: 16px;
          &:hover {
            padding: 16px;
          }
        }
      }
    }
    video {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
    }
    .scrubber-image-view {
      position: absolute;
      width: 100%;
      text-align: center;
      color: var(--brand_white);
      top: 0;
      display: flex;
      justify-content: center;
      height: 100%;
      .active-image {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-height: 100%;
        overflow: hidden;

        .add-to-incident-wrapper {
          display: flex;
          align-items: flex-start;
          gap: 8px;
          position: absolute;
          z-index: 100;
          top: 14px;
          left: 18px;

          .back-to-live {
            display: flex;
            padding: 16px;
            align-items: center;
            gap: 239px;
            border-radius: 12px;
            background: rgba(var(--greyscale-custom1), 0.4);
            /* Background Overlay */
            backdrop-filter: blur(4px);
          }

          .add-to-incident {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 16px;
            padding: 16px;
            border-radius: 12px;
            background: rgba(var(--greyscale-custom1), 0.4);
            /* Background Overlay */
            backdrop-filter: blur(4px);

            &-left-arrow {
              height: 24px;
              width: 24px;
            }

            &-folder {
              height: 24px;
              width: 24px;
            }

            &-label {
              margin-left: -8px;
              color: var(--brand_white);
              font-size: toRem(16px);
              font-weight: 600;
              line-height: toRem(20px);
            }
          }
        }

        .image-wrapper {
          height: 100%;
          width: 100%;
        }

        &.novideo {
          img {
            width: auto;
          }
        }

        img {
          max-width: 100%;
          height: 100%;
          max-height: 100%;
          object-fit: contain;
          aspect-ratio: 16/9;
          &.live-snapshot {
            object-fit: fill;
          }
        }
      }
      h2 {
        display: inline-block;
        background: rgba(var(--greyscale-custom1), 0.4);
        margin: 0;
        position: absolute;
        top: 80px;
      }
    }
    &.liveview {
      + .timeline-controls-page {
        .scrubber {
          .vis-timeline:after {
            background-color: var(--error_64);
          }
        }
      }
    }
  }
  .device-status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: var(--grayscale_16);
    aspect-ratio: 16 / 9;
    width: 100%;
    border-radius: 12px;
    cursor: default;

    .title-status {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(16px);
      line-height: 20px;
      text-align: center;
      color: var(--greyscale_56);
    }
  }
  .scrubber-wrapper {
    background: rgba(var(--greyscale-custom1), 0.4);
    padding: 0;
    // position: absolute;
    bottom: 16px;
    left: 16px;
    width: calc(100%);
    backdrop-filter: blur(2px);
    min-height: 100px;
    border-radius: 4px;
    color: #fff;

    .clipper-controls-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 4px;
      .clipper {
        &-active-time {
          margin: 15px 32px 5px 15px;
        }
        &-bubble {
          display: inline-flex;
          height: 25px;
          padding: 3px 12px 2px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-shrink: 0;
          border-radius: 12px;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(2px);
          color: var(--brand_white);
          text-align: center;
          font-size: toRem(12px);
          font-weight: 600;
          line-height: toRem(16px);
          margin: 11px 15px 2px;
        }
        &-duration {
        }
        &-action {
        }
      }
    }

    .timelines-controls-wrapper {
      display: flex;
      justify-content: space-between;
      padding-top: 14px;
      height: 42px;
      > div {
        width: 33.3%;
        &.live {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }
        &:first-child {
          text-align: left;
          padding-left: 10px;
        }
        &:last-child {
          text-align: right;
          padding-right: 20px;
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }
      }
      .timeline-controls-icons {
        .icons-image {
          display: inline-block;
          padding: 0 7px;

          &-folder-playing,
          &-folder-paused {
            height: 16px;
            width: 16px;
          }
        }
        .mins,
        .mins:hover,
        .mins:focus {
          background: rgba(var(--greyscale-custom3), 0.2);
          border-radius: 12px;
          padding: 4px 8px;
          color: #fff;
          font-weight: 600;
          font-size: toRem(12px);
          line-height: 16px;
          border: 0;
          margin-left: 7px;

          &.no-pointer {
            cursor: none;
            pointer-events: none;
          }
        }
      }
      .golive-view button,
      .golive-view button:hover,
      .golive-view button:focus {
        background: rgba(var(--error64-custom1), 0.2);
        backdrop-filter: blur(5px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 8px;
        padding: 4px 8px;
        color: #fff;
        font-size: toRem(14px);
        line-height: 20px;
        border: 0;
        margin-right: 8px;
        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: var(--error_64);
          border-radius: 50%;
          margin-right: 8px;
        }
      }
      .live {
        &::before {
          content: '';
          display: inline-block;
          width: 8px;
          height: 8px;
          background-color: var(--error_64);
          border-radius: 50%;
          margin-right: 8px;
        }
      }
    }
    &.live_btn_visible {
      .timeline-icons.golive {
        display: inline-block;
      }
    }
    &.visiblity-hidden {
      visibility: hidden;
    }
    .active_time {
      line-height: 16px;
      font-size: toRem(14px);
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      &.visiblity-hidden {
        visibility: hidden !important;
      }
      .icons-image {
        padding-left: 7px;
      }
    }
    .scrubber {
      position: relative;

      &.visiblity-hidden {
        .vis-timeline {
          visibility: hidden !important;
        }
      }

      &.midpoint-hidden {
        .vis-timeline:after {
          display: none;
        }
      }

      .vis-timeline:after {
        content: '';
        width: 2px;
        background-color: var(--warning_80);
        position: absolute;
        left: 50%;
        height: 34px;
        bottom: 0;
        margin-left: -1px;
      }
      .vis-foreground {
        .vis-group {
          height: 32px !important;
          cursor: move;
        }
      }
      .vis-panel {
        &.vis-background {
          .vis-axis {
            .vis-group {
              display: none;
            }
          }
        }
        .vis-content {
          .vis-background {
            .vis-group {
              display: none;
            }
          }
          .vis-item {
            &.vis-range {
              box-sizing: border-box;
              top: 0 !important;
              height: 32px;
              border: 0;
              border-radius: 0;
              background-color: rgba(var(--greyscale-custom3), 0.1);
            }
          }
        }
      }
      padding: 0 16px 4px;
      .vis-item {
        .vis-item-content {
          padding: 0;
        }
      }
      .vis-item.vis-box {
        border-radius: 50%;
        border-color: var(--brand_white);
        background-color: var(--brand_white);
        top: 15px !important;
        margin-top: -3px;
      }
      .vis-itemset {
        height: 32px !important;
        .vis-item {
          &.vis-point {
            top: auto;
            margin-left: 0;
            .vis-item {
              border-color: var(--brand_white);
              background-color: var(--brand_white);
              &.vis-dot {
                border-width: 4px;
                border-radius: 4px;
                top: 2.5px !important;
              }
            }
          }
        }
      }
      .vis-timeline {
        border: 0;
      }
      .vis-time-axis {
        .vis-text {
          color: rgba(var(--greyscale-custom3), 0.4);
          &.vis-major {
            margin-top: 0;
            margin-left: -18px;
            font-size: toRem(11px);
            line-height: 13px;
          }
        }
        .vis-grid.vis-minor {
          border-image-source: linear-gradient(
            180deg,
            rgba(var(--greyscale-custom3), 0) 0%,
            #ffffff 49.81%,
            rgba(var(--greyscale-custom3), 0) 100%
          );
          border-image-slice: 1;
          border-width: 1px;
          opacity: 0.6;
          height: 24px !important;
          // TODO: Watching for some time
          // top: 23px !important;
        }
        .vis-grid.vis-major {
          border-image-source: linear-gradient(
            180deg,
            rgba(var(--greyscale-custom3), 0) 0%,
            #ffffff 49.81%,
            rgba(var(--greyscale-custom3), 0) 100%
          );
          border-image-slice: 1;
          opacity: 0.6;
          border-color: var(--axis_border_timeline);
          max-height: 32px;
          top: auto !important;
          bottom: 0;
        }
      }
      .vis-panel.vis-bottom,
      .vis-panel.vis-center,
      .vis-panel.vis-left,
      .vis-panel.vis-right,
      .vis-panel.vis-top {
        border: 0;
      }
    }
    .clipper {
      position: relative;
      // width: calc(100% - 34px);
      width: calc(100% - 24px);
      height: 30px;
      margin: -35px auto auto;

      .clipper-resizable-container {
        position: absolute;
        z-index: 900;

        .clipper-resizable-marker {
          border-top-width: 2px;
          border-bottom-width: 2px;
          border-left-width: 10px;
          border-right-width: 10px;
          border-radius: 2px 0px 0px 2px;
          border-color: #fff;
          border-style: solid;
          height: 30px;
          position: relative;
          filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
          &.highlight {
            border-color: #0062f5;
            &.engage-left::before {
              content: '';
              position: absolute;
              top: -2px;
              left: -12px;
              text-align: left;
              border-color: #0062f5;
              border-top-width: 2px;
              border-bottom-width: 2px;
              border-left-width: 10px;
              border-style: solid;
              height: 30px;
            }
            &.engage-right::after {
              content: '';
              position: absolute;
              top: -2px;
              right: -12px;
              text-align: right;
              border-color: #0062f5;
              border-top-width: 4px;
              border-bottom-width: 4px;
              border-right-width: 10px;
              border-style: solid;
              height: 30px;
            }
          }
        }

        .clipper-handle {
          div:first-child {
            margin-left: -4px;
          }

          .three-dots {
            color: var(--greyscale_88);
            margin-top: 4px;

            &.left-handle {
              margin-left: 2px;
            }

            &.right-handle {
              margin-left: -8.5px;
            }
          }
        }
      }
    }
  }
}

.add-to-incident-confirmation-modal {
  .modal-content {
    min-width: 390px !important;
    width: 390px !important;
  }
}

.add-to-incident-modal,
.add-to-incident-confirmation-modal,
.create-clip-modal {
  .modal {
    &-header {
      padding-top: 0px;
      padding-bottom: 20px;
    }

    &-body {
      .add-to-incident {
        &-confirmation {
          &-description {
            color: var(--greyscale_56);
            font-size: toRem(16px);
            font-weight: 400;
            line-height: toRem(24px);
          }

          &-success-icon {
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin: 20px auto 5px;
          }
        }

        &-error {
          padding: 20px;
          border: 1px solid var(--error_24);
          border-radius: 12px;
          margin-bottom: 20px;
          background-color: var(--error_96);

          &-message {
            color: var(--error_48);
            font-size: toRem(14px);
            font-weight: 400;
            line-height: toRem(28px);
          }
        }

        &-radio {
          color: var(--greyscale_80);
          margin-right: -5px;
          padding-right: 0;

          &.Mui-checked {
            color: var(--primary_40);
          }

          &-group {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-wrap: wrap;
          }

          &-label,
          &-row {
            color: var(--greyscale_08) !important;
            display: flex;
            width: 100%;
            padding: 12px 16px;
            flex-direction: row;
            align-items: flex-start;
            gap: 12px;

            border-radius: 12px;
            background-color: var(--grayscale_96);
            margin-bottom: 8px;

            font-size: toRem(16px) !important;
            font-weight: 500;
            line-height: toRem(20px) !important;

            .add-to-incident-summary {
              display: flex;
              justify-content: flex-start;
              align-items: center;

              .add-to-incident-folder {
                color: var(--greyscale_08);
                height: 20px;
                width: 20px;
                margin-right: 8px;
              }

              .add-to-incident-summary-title {
                color: var(--greyscale_08);
                font-size: toRem(16px);
                font-weight: 500;
                line-height: toRem(20px);
              }
            }
          }
        }
      }
    }

    &-footer {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      border: 0;

      .add-to-incident,
      .add-to-incident-confirmation,
      .create-clip {
        &-button-group {
          width: 100%;
        }

        &-button-wrapper {
          width: 100%;
          margin-top: 8px;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 0px;
        }

        &-save-btn,
        &-go-to-incident-btn,
        &-watch-clip-btn {
          font-size: toRem(18px) !important;
          line-height: toRem(24px) !important;
          font-weight: 600 !important;
          height: 56px;

          &:hover {
            background-color: var(--primary_24);
          }

          &.disabled {
            color: var(--grayscale_32);
          }
        }

        &-create-new-btn.btn.btn-outline-secondary,
        &-back-to-video-btn.btn.btn-outline-secondary,
        &-close-btn.btn.btn-outline-secondary {
          font-size: toRem(18px);
          line-height: toRem(24px);
          font-weight: 600;
          width: 100%;
          height: 44px;
          color: var(--primary_40);
          border-color: var(--primary_40);

          &:focus {
            border-color: var(--primary_40);
            background-color: var(--brand_white);
          }

          &:hover {
            background-color: var(--primary_96);
          }
        }
      }
    }
  }
}
// Loader css

.loader-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(var(--greyscale-custom3), 0.2);
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 32px;
      height: 32px;
      margin: 8px;
      border: 3px solid #fff;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #eeeeee transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.live-streaming-container {
  position: relative;
  .timeline-controls-main-container {
    position: absolute;
    width: 100%;
    bottom: 0;
  }
}
