@import '../../assets/css/base';

.noti-dashboard-container {
  width: 100%;
  overflow: auto;

  .notification-container {
    width: 100%;
    .scroll-container {
      height: calc(100vh - 210px);
      overflow: auto;

      &::-webkit-scrollbar {
        display: none !important;
      }
    }
    .noti-item-data {
      flex-direction: row;

      .list-item {
        margin-left: 10px;
        width: 70%;

        .sticky-header {
          background-color: white;
          position: sticky;
          top: 0px;

          .header {
            text-align: left;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            padding-bottom: 16px;
            color: var(--greyscale_40);
          }
        }

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .row-container {
          width: 100%;
          padding-bottom: 8px;
          margin-bottom: 8px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 2px solid var(--grayscale_96);

          .event-conatiner {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            text-align: left;

            .image-layout {
              height: 80px;
              width: 80px;
              border: 2px solid var(--primary_32);
              border-radius: 8px;
              display: flex;
              justify-content: center;
              align-items: center;

              &.camera-online {
                background: var(--success_80);
                color: var(--tagvlm);
                border: 2px solid var(--success_24);
              }
              &.camera-offline {
                background: var(--error_64);
                color: var(--tagvlm);
                border: 2px solid var(--error_24);
              }

              .image-notification {
                height: 72px;
                width: 72px;
                border-radius: 6px;
                object-fit: cover;
              }
            }

            .type-layout {
              margin-left: -20px;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              height: 100%;
              margin-top: 5px;

              .bottom-noti-type {
                width: 28px;
                height: 28px;
                border-radius: 5px;
                background: var(--primary_96);
                border: 2px solid var(--brand_white);
                align-items: center;
                flex-direction: row;
                justify-content: center;
                display: flex;

                &.vehicle {
                  background: var(--tag_vlm_bg);
                  color: var(--tagvlm);
                }
                &.camera-online {
                  background: var(--success_64);
                  color: var(--tagvlm);
                }
                &.camera-offline {
                  background: var(--error_80);
                  color: var(--tagvlm);
                }
                .icon-events {
                  height: 15px;
                  width: 15px;
                }
              }
            }

            .notification-text-layout {
              margin-left: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              height: 100%;

              .header-text {
                font-style: normal;
                font-weight: 600;
                font-size: toRem(14px);
                line-height: 20px;
                color: var(--grayscale_16);
              }

              .title-text {
                font-style: normal;
                font-weight: 500;
                font-size: toRem(12px);
                line-height: 16px;
                color: var(--greyscale_40);
              }

              .title-sub-text {
                font-style: normal;
                font-weight: 400;
                font-size: toRem(14px);
                line-height: 20px;
                color: var(--greyscale_40);
              }
            }
          }

          .date-read-layout {
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
            display: flex;
            align-items: flex-end;

            .date-text {
              margin-top: 5px;
              font-style: normal;
              font-weight: 500;
              font-size: toRem(12px);
              line-height: 16px;
              color: var(--greyscale_40);
            }

            .red-circle {
              width: 10px;
              height: 10px;
              background: var(--error_112);
              border-radius: 50%;
            }
          }
        }
      }
    }

    .no-data-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 50%;
      left: 40%;

      .no-result {
        margin-top: 10px;
        font-style: normal;
        font-weight: 600;
        font-size: toRem(18px);
        line-height: 24px;
        text-align: center;
        color: var(--text_color);
      }

      .message-no-result {
        cursor: pointer;
        margin-top: 5px;
        font-style: normal;
        font-weight: 500;
        font-size: toRem(14px);
        line-height: 20px;
        text-align: center;
        color: var(--primary_40);
        font-style: normal;
      }
    }
  }
}
