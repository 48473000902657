@import '../../../assets/css/base';

.section {
  &-title {
    font-size: toRem(20px);
    font-weight: 700;
    margin-right: 24px;

    @media screen and (max-width: 1400px) {
      font-size: toRem(16px);
    }

    @media (min-width: 1000px) and (max-width: 1200px) {
      font-size: toRem(12px);
    }
  }

  &-view-details {
    .view-details-link {
      cursor: pointer;
      color: var(--primary_40);
      font-size: toRem(14px);
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-description {
    color: var(--greyscale_48);
    font-size: toRem(12px);
    font-weight: 400;
    text-align: left;

    &.uptime {
      font-size: toRem(11px);
    }
  }

  &-wrapper {
    border-radius: 12px;
    padding: 16px 30px;
    min-height: 300px;

    &.default-bg {
      background-color: var(--brand_white);
    }

    &.device-up-time-bg {
      background-color: transparent;
      padding: 23px 10px;
    }

    &.light-blue-bg {
      background-color: transparent;

      &.no-data {
        background-color: var(--brand_white);
        height: 300px;
      }
    }

    .analytics-section {
      margin-top: 16px;
      width: 100%;
    }
  }

  &-header {
    .top-right-nav {
      display: inline-flex;
      justify-content: flex-end;
      margin-top: 16px;

      &.device-up-time {
        margin-top: 0px;
      }
    }
  }
}
