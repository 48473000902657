
.manage-licenses {
  .page {
    &-title {
      padding-left: 0;
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 4px;
    }

    &-header {
      .purchase-button {
        height: 44px;
        padding: 8px 16px;

        &-label {
          font-size: 0.875rem;
        }
      }

      .toast-wrapper {
        text-align: left;
      }
    }
  }
.licenses-table-container {
    .licenses-table {
        thead {
          border-spacing: 0;

          th {
            border: 0;
            color: var(--greyscale_56);
            max-width: 120px;

            .header-sort {
              cursor: pointer;
              display: inline-flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .column-name {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100px;
              }

              .sort-icon {
                margin-right: 20px;
                font-size: 1.5rem;
              }
            }
          }
        }

        tbody {
          tr {
            &:hover {
              background-color: var(--greyscale_88);
            }
            td {
              max-width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              border-left: 0;
              border-right: 0;

              .date-field {
                display: inline-flex;
                align-items: center;

                .calendar-icon {
                  margin-right: 0.406rem;
                }

                .exclamation-icon {
                  margin-right: 0.406rem;
                  color: var(--warning_48);
                }

                .warning-date {
                  color: var(--warning_48);
                }
              }

              .status {
                &-field {
                  display: inline-flex;
                  align-items: center;
                }

                &-indicator {
                  margin-right: 0.375rem;
                  height: 0.625rem;
                  width: 0.625rem;
                }

                &-active,
                &-available {
                  color: var(--success_48);
                }

                &-inactive {
                  color: var(--greyscale_40);
                }

                &-expiring-soon {
                  color: var(--warning_64);
                }

                &-expired {
                  color: var(--error_48);
                }
              }
            }
          }
        }
      }
      @media(max-width: 1400px) {
        overflow-x: auto;
        
        // .licenses-table {
        //     tbody tr td {
        //         max-width: 100%;
        //     }
        //     thead tr th {
        //         max-width: 100%;
        //     }
        // }
      }
      .mobile{
        cursor: pointer;
      }
}
}

.enterTextManullyStyle {
  pointer-events: none !important;
  padding-top: 2%;
  // padding-left: 6.4% !important;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--greyscale_56);
  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.enterTextManullyHighlightedStyle {
  cursor: pointer !important;
  padding-top: 4%;
  // padding-left: 6.4% !important;
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left !important;
  color: var(--dark_orange);
  .row {
    margin-top: 19px;
    margin-left: 21px;
  }
  margin-left: 8px;
  width: 176px;
}

.didNotGetCodeTextStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  padding-left: 3px;
  margin-top: -12px;
}

.clickToResendTextStyle {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  text-decoration: underline;
  padding-left: 6px;
  margin-top: -12px;
}

.clickToResendTextStyle1 {
  cursor: pointer !important;
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--greyscale_56);
  // text-decoration: underline;
  padding-left: 6px;
  margin-top: -12px;
}

.large-text-box {
  height: 64px !important;
  width: 64px !important;
  text-align: center !important;
  font-family: Noto Sans !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0em !important;
}

.license-name {
  .col-md-2 {
    width: 14% !important;
  }
}

.cofirmationCodeMessageStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.confirmStyle {
  font-family: Noto Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.LicenseSuccessRoundStyle {
  margin-left: -15px;
}

.LicenseSuccessMarkStyle {
  margin-left: -64px;
}

.LicenseSuccessMessageStyle {
  font-family: Noto Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 21px;
}

.LicenseTextFieldStyle {
  margin-top: 12px;
}

.licenseRemoveIconStyle {
  margin-top: 12px;
}

.removableLicenseTextFieldStyle {
 padding-right: 0% !important;
}
@media (max-width: 570px) {
  img.licenseRemoveIconStyle{
  display:block;
  float:none;
  margin: 0px 0px 0px auto; 
  }
  .erroMessageIconStyle {
    margin-left: 5px !important;
  }
  .removableLicenseTextFieldStyle {
   padding-right: 4% !important;
   }
  
} 

.mark-icon-class {
  fill: none !important;
  stroke: none !important;
}

.erroMessage {
  font-family: Noto Sans;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 6px;
}

.erroMessageIconStyle {
  margin-left: 21px;
}

.marginBottomKey {
  margin-top: -14px !important;
}

.title-container {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: var(--brand_white);
  margin-top: 9px;
}

.modal-dialog .modal-content .modal-body .modal-form .form-control {
  &:hover,
  &:focus {
    border-color: var(--light_orange) !important;
  }
}

.site-modal-close {
  cursor: pointer !important;
  color: var(--dark_gray);
  padding: 1.5px;
  font-weight: 600;
  stroke-width: 1;
}
.site-modal-close:hover {
  color: var(--button-color-1); /* Hover color */
}
.site-modal-close:active {
  color: var(--dark_orange); /* Color on press */
}

.errorStyle1 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--error_64);
  padding-left: 3px;
  margin-top: -15px;
  padding-bottom: 12px;
}

.addLicenseBtnStyle {
  outline: none !important;
}

.plusIconClass {
  margin-right: 5px;
}